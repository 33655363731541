import React from "react"

const OrderTotal = ({ order }) => {
  return (
    <div className="order-total">
      {order.totalTickets.map(item => (
        <a
          href={item.url}
          key={item.id || item.name}
          disabled={item.quantity < 1 || item.url === ""}
        >
          <span className="order-total-count">{item.quantity}</span>
          {item.name}
        </a>
      ))}
    </div>
  )
}

export default OrderTotal
